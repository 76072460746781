import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface IconButtonWithTextProps {
  icon: React.ReactNode;
  text: string;
  to?: string;
  onClick?: () => void;
}

export const IconButtonWithText: React.FC<IconButtonWithTextProps> = ({ icon, text, to, onClick }) => {
  const button = (
    <IconButton color="inherit" onClick={onClick} className="flex flex-col items-center">
      {icon}
      {text && <Typography variant="caption" className="mt-1">{text}</Typography>}
    </IconButton>
  );

  return to ? <Link to={to}>{button}</Link> : button;
};