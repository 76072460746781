import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecords } from '../contexts/RecordsContext';
import { RecordData } from './Sessions/types';
import { ActivityViewer } from '../components/Activity/ActivityViewer';
import { HeaderWithBack } from '../components/common/HeaderWithBack.tsx';
import { useLanguage } from '../contexts/LanguageContext.tsx';

const ActivityView: React.FC = () => {
    const { id } = useParams();
    // const navigate = useNavigate();
    const { record, loading, error, fetchRecord } = useRecords();
    const { currentLanguage } = useLanguage();
    if (loading) {
        return (
            <div className="flex justify-center my-4 container mx-auto">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mx-auto">
                <div className="text-xl text-red-600 text-center">
                    {error}
                </div>
            </div>
        );
    }

    if (!record && id) {
        fetchRecord(parseInt(id), "activities", currentLanguage?.id || 0);
    }

    console.log(record);
    return (

        <div className="container mx-auto max-w-7xl">
            <HeaderWithBack>
                <div className="text-2xl font-semibold text-gray-800">{record?.name || record?.title}</div>
            </HeaderWithBack>

            {record && <ActivityViewer activity={record as RecordData} />}
        </div>
    );
};

export default ActivityView;
