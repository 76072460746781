import React from "react";
import RecordList from "../components/RecordList";
import SearchSlideContainer from "../components/SearchSlideContainer";
import { useScreenMode } from "../contexts/ScreenModeContext";

const Home: React.FC = () => {
  const { isMobile } = useScreenMode();

  const commonContent = (
    <>
      <SearchSlideContainer />
      <RecordList />
    </>
  );

  return (
    <div>
      <div className={isMobile ? "mt-14" : ""}>
        {isMobile ? (
          <div className="mt-2 mx-auto">
            {commonContent}
          </div>
        ) : (
          <div className="mt-2 mx-auto px-4">
            {commonContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
