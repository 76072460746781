import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { useScreenMode } from '../contexts/ScreenModeContext';
import SaveFabComponent from './SaveFabComponent';

interface ActionItem {
    label: string;
    name: string;
    to: string;
}

interface FloatingActionMenuProps {
    actions: ActionItem[];
}

const FloatingActionMenu: React.FC<FloatingActionMenuProps> = ({ actions }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isMobile } = useScreenMode();
    const location = useLocation();
    const isCreateActivity = location.pathname === '/createActivity';

    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <div className={`fixed right-8 md:right-12 lg:right-16 xl:right-24 2xl:right-32 z-50 ${isMobile ? 'bottom-20' : 'bottom-8'}`}>
            {/* Submenu Actions */}
            {!isCreateActivity && (
                <div className={`absolute bottom-full right-1/2 transform translate-x-1/2 mb-4 transition-all duration-300 ease-in-out ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`}>
                    <div className="flex flex-col-reverse items-center space-y-4 space-y-reverse">
                        {actions.map((action, index) => (
                            <Link
                                key={index}
                                to={action.to}
                                className="flex items-center justify-start space-x-2 bg-white hover:bg-gray-100 opacity-90 text-gray-800 rounded-full shadow-md px-3 py-2 transition-transform"
                                onClick={toggleMenu}
                                
                            >
                                <Add className="text-green-500" fontSize="small" />
                                <span className="text-sm font-semibold">{action.label}</span>
                            </Link>
                        ))}
                    </div>
                </div>
            )}

            {/* Main FAB */}
            {isCreateActivity ? (
                <SaveFabComponent />
            ) : (
                <button
                    onClick={toggleMenu}
                    className="flex items-center justify-center w-16 h-16 bg-green-500 hover:bg-green-600 text-white rounded-full shadow-lg transition-transform"
                >
                    <Add fontSize="large" />
                </button>
            )}
        </div>
    );
};

export default FloatingActionMenu;
