import React from "react";
import { Grid, TextField, InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

interface FilterGridProps {
  options: string[];
  label: string;
  icon: React.ReactElement;
  stackVertically?: boolean;
  error?: boolean;
  readOnly?: boolean;
  keywords?: string | string[];
  onChange: (selectedOptions: string[]) => void;
}

const FilterGrid: React.FC<FilterGridProps> = ({
  options,
  label,
  icon,
  stackVertically,
  error = false,
  readOnly = false,
  keywords,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

  const handleAutocompleteChange = (_event: any, selectedOptions: string[]) => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions); // Notify parent component of changes
  };

  const hasSelectedOptions = selectedOptions.length > 0;

  const iskeywordsEmpty = (keywords: string | string[] | undefined): boolean => {
    return (
      keywords === undefined ||
      (typeof keywords === 'string' && keywords.trim() === '') ||
      (Array.isArray(keywords) && keywords.length === 0)
    );
  }

  return (
    <Grid
      item
      xs={12}
      sm={stackVertically ? 12 : 6}
      md={stackVertically ? 12 : 4}
    >
      {readOnly ? (
        <TextField
          label={iskeywordsEmpty(keywords) ? "" : label}
          variant="outlined"
          fullWidth
          value={Array.isArray(keywords) ? keywords.join(', ') : keywords}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          }}
        />
      ) : (
        <Autocomplete
          multiple
          options={options}
          value={selectedOptions}
          onChange={handleAutocompleteChange}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth
              error={error && !hasSelectedOptions}
              helperText={error && !hasSelectedOptions ? "* Obligatorisk" : ""}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">{icon}</InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    </Grid>
  );
};

export default FilterGrid;
