import React, { createContext, useContext, useState, useCallback } from 'react';
import { RecordData, SearchData, SessionRecordData } from '../pages/Sessions/types';
import { BACKEND_REST_URL } from '../main';

interface RecordsContextType {
    record: RecordData | SessionRecordData | null;
    setRecord: (record: RecordData | SessionRecordData | null) => void;
    records: RecordData[];
    loading: boolean;
    error: string | null;
    total: number;
    fetchRecords: (skip: number, limit: number, searchData: SearchData, language_id: number) => Promise<void>;
    fetchRecord: (id: number, activityOrSession: "activities" | "sessions", language_id: number) => Promise<void>;
    clearRecords: () => void;
}

const RecordsContext = createContext<RecordsContextType | undefined>(undefined);

export const RecordsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [record, setRecord] = useState<RecordData | SessionRecordData | null>(null);
    const [records, setRecords] = useState<RecordData[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [total, setTotal] = useState(0);

    const FetchRecordCall = async (id: number, activityOrSession: "activities" | "sessions", language_id: number) => {
        const url = `${BACKEND_REST_URL}/api/${activityOrSession}/${id}/${language_id}`;
        setLoading(true);
        let data;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }
            data = await response.json();
            return data;
        } catch (err) {
            // setError(err instanceof Error ? err.message : 'An error occurred');
            return null;
        } finally {
            setLoading(false);
        }
    };

    const fetchRecord = useCallback(async (id: number, activityOrSession: "activities" | "sessions", language_id: number) => {
        try {
            const data = await FetchRecordCall(id, activityOrSession, language_id);
            setRecord(data);
            return data;
        } catch (err) {
            setRecord(null);
            throw err;
        }
    }, []);

    const fetchRecords = useCallback(async (skip: number, limit: number, searchData: SearchData, language_id: number) => {
        const activityOrSession = searchData.show_activities ? "activities" : "sessions";
        const url = `${BACKEND_REST_URL}/api/${activityOrSession}/search?skip=${skip}&limit=${limit}`;

        setLoading(true);
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(searchData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }

            const data = await response.json();
            setTotal(data.total);

            let bodies: RecordData[] = [];
            // const activity_ids = data[activityOrSession].map((record: RecordData) => record.id).join(',');
            const params = new URLSearchParams();
            if (data.total > 0) {
                params.append('language_id', language_id.toString());
                data[activityOrSession].forEach((record: RecordData) => {
                    params.append('activity_ids', record.id.toString());
                });
                const bodies_response = await fetch(`${BACKEND_REST_URL}/api/${activityOrSession}/bodies?${params.toString()}`);
                bodies = await bodies_response.json();
            }
            else {
                bodies = [];
            }


            // if (searchData.show_activities) {
            //     data.activities = data.activities
            //     data.total = data.activities.length;
            // }

            setRecords((prevRecords) =>
                skip === 0
                    ? bodies
                    : [...prevRecords, ...bodies]
            )
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    }, []);

    const clearRecords = useCallback(() => {
        setRecords([]);
        setTotal(0);
        setError(null);
    }, []);

    return (
        <RecordsContext.Provider
            value={{
                records,
                loading,
                error,
                record,
                setRecord,
                total,
                fetchRecords,
                fetchRecord,
                clearRecords,
            }}
        >
            {children}
        </RecordsContext.Provider>
    );
};

export const useRecords = () => {
    const context = useContext(RecordsContext);
    if (context === undefined) {
        throw new Error('useRecords must be used within a RecordsProvider');
    }
    return context;
}; 