import React from 'react';
import { RecordData } from '../../pages/Sessions/types';
import { QuillViewer } from '../createActivity/richEditor';
import { TagGrid } from './TagGrid';

interface ActivityViewerProps {
    activity: RecordData;
}

export const ActivityViewer: React.FC<ActivityViewerProps> = ({ activity }) => {
    console.log(activity);
    return (
        <div className="container mx-auto px-4 max-w-7xl">
            <div className="prose max-w-none">
                <QuillViewer content={activity.description || ""} />
            </div>
            <div className="border-t border-gray-400 my-8"></div>

            {activity.videoUrl && (
                <>
                    <div className="aspect-w-16 aspect-h-9">
                        <iframe
                            src={`https://www.youtube.com/embed/${activity.videoUrl.split('v=')[1].split('&')[0]}`}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="w-full h-full rounded-lg"
                        ></iframe>
                    </div>
                    <div className="border-t border-gray-400 my-8"></div>
                </>
            )}

            <TagGrid activity={{
                skill_levels: activity.skill_levels,
                focus_groups: activity.focus_groups,
                categories: activity.categories,
                branches: activity.branches,
                environments: activity.environments,
                disabilities: activity.disabilities,
                equipment: activity.equipment
            }} />


        </div>
    );
}; 