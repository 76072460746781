import { useTranslation } from "react-i18next";
import { useCreateActivity } from "../../contexts/CreateActivityContext";
import { QuillEditor } from "../createActivity/richEditor";
import { HeaderWithBack } from "../common/HeaderWithBack";
import { TitleInput } from "../createActivity/activityFormSelectors";
import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { TagGrid } from "./TagGrid";
import { useOptions } from "../../contexts/OptionsContext";
import { useLanguage } from "../../contexts/LanguageContext";
import { Language } from "../../pages/Sessions/types";
import { LanguageSharp, ArrowDropDown } from "@mui/icons-material";
import LanguageModal from "../translationBar/LanguageModal";

const YouTubeUrlModal = ({ isOpen, onClose, onSubmit }: { isOpen: boolean, onClose: () => void, onSubmit: (url: string) => void }) => {
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const { t } = useTranslation();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(youtubeUrl);
        setYoutubeUrl("");
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <DialogPanel className="bg-white rounded-lg p-6 w-full max-w-md">
                    <DialogTitle as="h2" className="text-lg font-medium mb-4">
                        {t('createActivity.addYouTubeVideo')}
                    </DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="url"
                            value={youtubeUrl}
                            onChange={(e) => setYoutubeUrl(e.target.value)}
                            placeholder="https://www.youtube.com/watch?v=..."
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                            required
                            pattern="^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+"
                        />
                        <div className="flex justify-end gap-2">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                            >
                                {t('common.cancel')}
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                {t('common.add')}
                            </button>
                        </div>
                    </form>
                </DialogPanel>
            </div>
        </Dialog>
    );
};

const MediaBox = () => {
    const [showYouTubeModal, setShowYouTubeModal] = useState(false);
    const [videoThumbnail, setVideoThumbnail] = useState<string | null>(null);
    const { t } = useTranslation();
    const { handleImageUpload, handleVideoUpload } = useCreateActivity();

    const getYouTubeThumbnail = (url: string) => {
        const videoId = url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([\w-]{11})/)?.[1];
        return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : null;
    };

    const handleYouTubeSubmit = (url: string) => {
        handleVideoUpload(url);
        const thumbnail = getYouTubeThumbnail(url);
        setVideoThumbnail(thumbnail);
    };

    const handleFileUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;
        input.onchange = (e: any) => {
            if (e.target.files) {
                handleImageUpload(e.target.files);
            }
        };
        input.click();
    };

    return (
        <>
            <div className="w-full bg-white rounded-lg shadow-lg p-6 transition-all duration-300 ease-in-out">
                <div className="flex flex-col md:flex-row gap-4 w-full">
                    {/* Video Upload Option */}
                    <div
                        className="flex-1 flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:bg-blue-50 transition-all duration-200 cursor-pointer"
                        onClick={() => setShowYouTubeModal(true)}
                    >
                        {videoThumbnail ? (
                            <div className="relative w-full aspect-video">
                                <img
                                    src={videoThumbnail}
                                    alt="Video thumbnail"
                                    className="w-full h-full object-cover rounded"
                                />
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-200">
                                    <span className="text-white text-sm">{t('createActivity.changeVideo')}</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <svg className="w-10 h-10 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                </svg>
                                <span className="text-sm font-medium text-gray-700">{t('createActivity.uploadVideo')}</span>
                            </>
                        )}
                    </div>
                    <div className="hidden md:block w-px bg-gray-200"></div>
                    <div
                        className="flex-1 flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 hover:bg-blue-50 transition-all duration-200 cursor-pointer"
                        onClick={handleFileUpload}
                    >
                        <svg className="w-10 h-10 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <span className="text-sm font-medium text-gray-700">{t('createActivity.uploadImage')}</span>
                    </div>
                </div>
            </div>

            <YouTubeUrlModal
                isOpen={showYouTubeModal}
                onClose={() => setShowYouTubeModal(false)}
                onSubmit={handleYouTubeSubmit}
            />
        </>
    );
};


const LanguageSelector = ({ currentLanguage, setActivityLanguage }: { currentLanguage: Language | null, setActivityLanguage: (language: Language) => void }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const handleLanguageChange = (languageCode: string) => {
        const { languages } = useLanguage();
        const selectedLanguage = languages.find(lang => lang.code === languageCode);
        if (selectedLanguage) {
            setActivityLanguage(selectedLanguage);
        }
    };

    return (
        <>
            <button 
                onClick={() => setIsModalOpen(true)} 
                className="ml-auto flex items-center gap-2 px-4 py-2 border rounded-md hover:bg-gray-50"
            >
                <LanguageSharp className="text-gray-600" />
                <span>{currentLanguage?.name || t('common.selectLanguage')}</span>
                <ArrowDropDown className="text-gray-600" />
            </button>

            <LanguageModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onLanguageChange={handleLanguageChange}
            />
        </>
    );
};

export const ActivityCreate: React.FC = () => {
    const {
        activityPost,
        handleInputChange,
        errors,
        isChecked,
    } = useCreateActivity();

    const { skillLevelOptions, focusGroupOptions, categoryOptions, branchOptions, environmentOptions, disabilityOptions, equipmentOptions } = useOptions();


    const { activityLanguage, setActivityLanguage } = useLanguage();
    const errorBorder = !!errors.name || !!errors.description ? 'border-red-500' : 'border-gray-200';

    return (
        <div className="container mx-auto max-w-7xl ">
            <div className="container mx-auto px-4 max-w-7xl">

                <HeaderWithBack>
                    <TitleInput
                        name="name"
                        title={activityPost.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        hasError={!!errors.name}
                    />

                    <LanguageSelector currentLanguage={activityLanguage} setActivityLanguage={setActivityLanguage} />
                </HeaderWithBack>

                <div className="space-y-4">
                    {/* {errors.description && <p className="text-red-500 text-sm">{t('createActivity.common.error')}</p>} */}
                    <div className={`border-b-2 ${errorBorder}`}>

                        <QuillEditor
                            value={activityPost.description}
                            onChange={(value) => handleInputChange('description', value)}
                            hasError={!!errors.description}
                        />
                    </div>
                    <div className="flex w-full items-center justify-center border-4 border-gray-300 rounded-lg hover:cursor-pointer hover:bg-gray-100">
                        <MediaBox />
                    </div>
                    <TagGrid
                        activity={{
                            skill_levels: skillLevelOptions,
                            focus_groups: focusGroupOptions,
                            categories: categoryOptions,
                            branches: branchOptions,
                            environments: environmentOptions,
                            disabilities: disabilityOptions,
                            equipment: equipmentOptions
                        }}
                        isEditable={true}
                        onChange={(section, id) => handleInputChange(section, id.toString())}
                        isChecked={isChecked}
                    />
                </div>
                {/* <div className="flex justify-end">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={() => handlePost(activityPost, [])}>
                        {t('common.save')}
                    </button>
                </div> */}
            </div>
        </div>
    );
};
