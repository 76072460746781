import { Box, Chip, Divider, IconButton, TextField, Typography } from "@mui/material";
import SessionImageCarousel from "../../components/Sessions/SessionImageCarousel";
import { QuillEditor } from "../../components/createActivity/richEditor";
import { useFormContext } from "../../contexts/FormContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import { BACKEND_REST_URL } from "../../main";
import { useTranslation } from 'react-i18next';

const getStoredData = (key: string) => {
    const storedData = localStorage.getItem(key);
    if (storedData) {
        return JSON.parse(storedData);
    }
    return null;
};

const InformationStep = () => {
    const { t } = useTranslation();
    const {
        setTitle,
        title,
        setSelectedCategory,
        selectedCategory,
        setSections,
        setDescription,
        setSectionTitles,
        setSelectedActivities,
        description,
        sections,
        sectionTitles,
        selectedActivities,
        errors,
    } = useFormContext();

    const [categories, setCategory] = useState<string[]>([]);

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const storedCategories = getStoredData("categories");
                if (storedCategories) {
                    setCategory(storedCategories);
                } else {
                    const categoryResponse = await fetch(BACKEND_REST_URL + "/api/categories/");
                    const categoriesObj = await categoryResponse.json();
                    setCategory(categoriesObj.map((category: { name: string }) => category.name));
                }
            } catch (error) {
                console.error("Failed to fetch filter options", error);
            }
        };
        fetchCategory();
    }, []);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleCategoryChange = (category: string) => {
        setSelectedCategory(
            selectedCategory.includes(category)
                ? selectedCategory.filter((cat) => cat !== category)
                : [...selectedCategory, category]
        );
    };

    const handleAddSection = () => {
        setSections(sections + 1);
        setSectionTitles([...sectionTitles, `${t('createSession.sectionStep.sectionTitle')} ${sections + 1}`]);
        setSelectedActivities([...selectedActivities, []]);
    };

    const handleRemoveSection = () => {
        if (sections > 1) {
            setSections(sections - 1);
            setSectionTitles(sectionTitles.slice(0, -1));
            setSelectedActivities(selectedActivities.slice(0, -1));
        }
    };

    return (
        <div className="container mx-auto p-4 lg:p-6">
            <div className="text-center mb-6 lg:mb-8">
                <Typography variant="h5" className="font-semibold text-lg lg:text-2xl">
                    {t('createSession.informationStep.createSession')}
                </Typography>
            </div>

            <TextField
                variant="outlined"
                fullWidth
                required
                label={t('createSession.informationStep.title')}
                value={title}
                onChange={handleTitleChange}
                placeholder={t('createSession.informationStep.titlePlaceholder')}
                className="mb-6"
                error={Boolean(errors.title)}
                helperText={errors.title}
            />

            <Divider className="py-4 my-6 lg:my-8">
                <b>{t('createSession.conclusionStep.information')}</b>
            </Divider>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
                <div className="bg-white rounded-lg shadow-md p-4 lg:p-6">
                    <Typography variant="h6" className="mb-4">
                        {t('createSession.informationStep.category')}
                    </Typography>
                    <Box className="mb-6">
                        {categories.map((cat) => (
                            <Chip
                                key={cat}
                                label={cat}
                                clickable
                                color={selectedCategory.includes(cat) ? "primary" : "default"}
                                onClick={() => handleCategoryChange(cat)}
                                className="mr-2 mb-2"
                            />
                        ))}
                    </Box>
                    {errors.selectedCategory && (
                        <Typography color="error" variant="body2" className="mt-2">
                            {errors.selectedCategory}
                        </Typography>
                    )}

                    <div className="mt-6">
                        <Typography variant="h6" className="mb-4">
                            {t('createSession.informationStep.numberOfSections')}
                        </Typography>
                        <div className="flex items-center justify-center space-x-4">
                            <IconButton
                                color="secondary"
                                onClick={handleRemoveSection}
                                disabled={sections <= 1}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <div className="text-xl lg:text-2xl font-bold">{sections}</div>
                            <IconButton color="primary" onClick={handleAddSection}>
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-md p-4 lg:p-6">
                    <SessionImageCarousel />
                </div>
            </div>

            <Divider className="py-4 mt-6 lg:mt-8">
                <b>{t('createSession.informationStep.sessionDescription')}</b>
            </Divider>

            <QuillEditor value={description} onChange={setDescription} />
            {errors.description && (
                <Typography color="error" variant="body2" className="mt-2">
                    {errors.description}
                </Typography>
            )}
        </div>
    );
};

export default InformationStep;