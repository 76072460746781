import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SignInForm from "../components/SignInForm";
import { useAuth } from "../contexts/AuthContext";

const defaultTheme = createTheme();

export const SignInPage = () => {
  const [isSignUp, setIsSignUp] = React.useState(false);
  const {
    isLoggedIn,
    signIn,
    signOut,
    signUp,
    error,
    signInError,
    passwordMismatch,
    setPasswordMismatch,
  } = useAuth();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    if (isSignUp) {
      await signUp(formData);
    } else {
      await signIn(formData);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isLoggedIn && (
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
          )}
          <Typography component="h1" variant="h5">
            {isSignUp ? "Ny bruker" : "Logg in"}
          </Typography>
          {error && (
            <Typography variant="body2" color="error" gutterBottom>
              {error}
            </Typography>
          )}
          {signInError && (
            <Typography variant="body2" color="error" gutterBottom>
              {signInError}
            </Typography>
          )}
          {!isLoggedIn ? (
            <SignInForm
              isSignUp={isSignUp}
              onSubmit={handleSubmit}
              passwordMismatch={passwordMismatch}
              setPasswordMismatch={setPasswordMismatch}
              setIsSignUp={setIsSignUp}
            />
          ) : (
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "error.main", color: "white" }}
              onClick={signOut}
            >
              Logg ut
            </Button>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignInPage;
