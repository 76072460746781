import React, { createContext, useContext, useState, ReactNode } from 'react';
import { RecordDataPost, ImageDataPost } from '../pages/Sessions/types';

import { BACKEND_REST_URL } from '../main';

interface CreateActivityContextType {
    activityPost: RecordDataPost;
    setActivityPost: React.Dispatch<React.SetStateAction<RecordDataPost>>;
    images: ImageDataPost[];
    setImages: React.Dispatch<React.SetStateAction<ImageDataPost[]>>;
    errors: { [key: string]: string };
    setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    handleInputChange: (name: string, value: string) => void;
    handlePost: (activity: RecordDataPost, images: ImageDataPost[], language_id: number) => Promise<void>;
    handleImageUpload: (newFiles: FileList) => void;
    handleVideoUpload: (string: string) => void;
    deleteImageAtIndex: (indexToDelete: number) => void;
    handleThumbnailChange: (index: number) => void;
    isChecked: (section: string, value: string) => boolean;
    validateActivity: () => boolean;
}

const CreateActivityContext = createContext<CreateActivityContextType | undefined>(undefined);

interface CreateActivityProviderProps {
    children: ReactNode;
}

export const CreateActivityProvider: React.FC<CreateActivityProviderProps> = ({ children }) => {
    const initialActivity: RecordDataPost = {
        name: '',
        description: '',
        language_id: 0,
        branches: [],
        environments: [],
        skill_levels: [],
        categories: [],
        focus_groups: [],
        disabilities: [],
        equipment: [],
    };

    const [activityPost, setActivityPost] = useState<RecordDataPost>(initialActivity);
    const [images, setImages] = useState<ImageDataPost[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleInputChange = (name: string, value: string) => {
        if (Array.isArray(activityPost[name as keyof RecordDataPost])) {
            if ((activityPost[name as keyof RecordDataPost] as string[]).includes(value)) {
                setActivityPost(prev => ({
                    ...prev,
                    [name]: (prev[name as keyof RecordDataPost] as string[]).filter((item: string) => item !== value),
                }));
            } else {
                setActivityPost(prev => ({
                    ...prev,
                    [name]: [...(prev[name as keyof RecordDataPost] as string[]), value],
                }));
            }
        } else {
            setActivityPost(prev => ({
                ...prev,
                [name]: value,
            }));
        }
        console.log(activityPost);
    };
    const handleVideoUpload = (string: string) => {
        setActivityPost(prev => ({
            ...prev,
            videoUrl: string,
        }));
    };
    const isChecked = (name: string, value: string) => {
        if (!activityPost[name as keyof RecordDataPost]) {
            return false;
        }

        return (activityPost[name as keyof RecordDataPost] as string[]).includes(value);
    };

    const validateActivity = (): boolean => {
        const newErrors: { [key: string]: string } = {};
        const requiredFields = ['name', 'description', 'branches', 'environments', 'skill_levels', 'categories', 'focus_groups'];

        requiredFields.forEach(field => {
            const value = activityPost[field as keyof RecordDataPost];
            if (!value || (Array.isArray(value) && value.length === 0)) {
                newErrors[field] = `${field} is required`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePost = async (activity: RecordDataPost, images: ImageDataPost[], language_id: number) => {
        activity.language_id = language_id;
        console.log(activity);
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${BACKEND_REST_URL}/api/activities/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(activity),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText} response: ${response}`);
            }

            const activityData = await response.json();

            if (images.length > 0) {
                await Promise.all(images.map(async ({ file, isThumbnail }) => {
                    const formData = new FormData();
                    formData.append('file', file);
                    await fetch(`${BACKEND_REST_URL}/api/activity/${activityData.id}/images?is_thumbnail=${isThumbnail}`, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                }));
            }
        } catch (error) {
            console.error('Error posting activity:', error);
            throw error;
        }
    };

    const handleImageUpload = (newFiles: FileList) => {
        const fileArray = Array.from(newFiles);
        const newImagePosts: ImageDataPost[] = fileArray.map((file, index) => ({
            file,
            isThumbnail: images.length === 0 && index === 0,
        }));
        setImages(prev => [...prev, ...newImagePosts]);
    };

    const deleteImageAtIndex = (indexToDelete: number) => {
        setImages(prev => prev.filter((_, index) => index !== indexToDelete));
    };

    const handleThumbnailChange = (index: number) => {
        setImages(prev => prev.map((image, idx) => ({
            ...image,
            isThumbnail: idx === index,
        })));
    };




    return (
        <CreateActivityContext.Provider
            value={{
                activityPost,
                setActivityPost,
                images,
                setImages,
                errors,
                setErrors,
                handleInputChange,
                isChecked,
                handlePost,
                handleImageUpload,
                handleVideoUpload,
                deleteImageAtIndex,
                handleThumbnailChange,
                validateActivity,
            }}
        >
            {children}
        </CreateActivityContext.Provider>
    );
};

export const useCreateActivity = (): CreateActivityContextType => {
    const context = useContext(CreateActivityContext);
    if (!context) {
        throw new Error('useCreateActivity must be used within a CreateActivityProvider');
    }
    return context;
};
