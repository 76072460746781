import React from "react";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, TextField, List, ListItem, ListItemText, Divider, DialogActions, IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Close } from "@mui/icons-material";
import { useLanguage } from "../../contexts/LanguageContext";

interface LanguageModalProps {
    isOpen: boolean;
    onClose: () => void;
    onLanguageChange: (languageCode: string) => void;
}

const LanguageModal: React.FC<LanguageModalProps> = ({ isOpen, onClose, onLanguageChange }) => {
    const { t } = useTranslation();
    const { languages, filteredLanguages, handleLanguageSelect } = useLanguage();

    const handleSelection = (languageCode: string) => {
        handleLanguageSelect(languageCode);
        onLanguageChange(languageCode);
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogActions>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                >
                    <Close />
                </IconButton>
            </DialogActions>

            <DialogTitle>{t('common.selectLanguage')}</DialogTitle>
            <DialogContent>
                <Autocomplete
                    options={languages}
                    getOptionLabel={(option) => `${option.name} (${option.code})`}
                    renderInput={(params) => <TextField {...params} label={t('common.searchLanguage')} />}
                    onChange={(_, value) => value && handleSelection(value.code)}
                    sx={{ width: 300 }}
                />
                <List>
                    {filteredLanguages.map((lang, index) => (
                        <React.Fragment key={lang.code}>
                            {index > 0 && !lang.isLocale && filteredLanguages[index - 1].isLocale && (
                                <Divider />
                            )}
                            <ListItem
                                button
                                onClick={() => handleSelection(lang.code)}
                                style={{ fontWeight: lang.isLocale ? 'bold' : 'normal' }}
                            >
                                <ListItemText primary={`${lang.name} (${lang.code})`} />
                            </ListItem>
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default LanguageModal;