

type ImagePreviewProps = {
    images: string
};

const ImagePreview: React.FC<ImagePreviewProps> = ({ images }) => (
    <div className="bg-white aspect-square rounded-lg border-2 shadow-md p-1  ">
        <div className="flex  justify-center items-center relative">
            {images.length > 0 ? (
                <img
                    src={images}
                    alt="Selected"
                    className="object-cover  w-full  rounded-lg"
                />
            ) : (
                <div className="text-center">
                    <p className="text-gray-500">Ingen bilde tilgjengelig</p>
                </div>
            )}
        </div>
    </div>
);

export default ImagePreview;