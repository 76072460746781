import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FilterGrid from "./FilterGrids";
import ForestIcon from "@mui/icons-material/Forest";
import AccessibleIcon from "@mui/icons-material/Accessible";
import PeopleIcon from "@mui/icons-material/People";
import KeyIcon from "@mui/icons-material/Key";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import LandscapeIcon from "@mui/icons-material/Landscape";
import { RecordData, } from "../../pages/Sessions/types";
import { BACKEND_REST_URL } from "../../main";
import { useTranslation } from 'react-i18next';

interface FilterProps {
    stackVertically?: boolean;
    error?: boolean;
    readOnly?: boolean;
    activity?: RecordData;
    onChangeBranch?: (selectedOptions: string[]) => void;
    onChangeEnvironment?: (selectedOptions: string[]) => void;
    onChangeSkillLevel?: (selectedOptions: string[]) => void;
    onChangeFocusGroup?: (selectedOptions: string[]) => void;
    onChangeCategories?: (selectedOptions: string[]) => void;
    onChangeDisabilities?: (selectedOptions: string[]) => void;
}

const Filter: React.FC<FilterProps> = ({
    stackVertically = false,
    error = false,
    readOnly = false,
    activity,
    onChangeBranch = () => { },
    onChangeEnvironment = () => { },
    onChangeSkillLevel = () => { },
    onChangeFocusGroup = () => { },
    onChangeCategories = () => { },
    onChangeDisabilities = () => { },
}) => {
    const { t } = useTranslation();
    const [filterOptions, setFilterOptions] = useState({
        branches: [],
        environments: [],
        skillLevels: [],
        focusGroups: [],
        categories: [],
        disabilities: [],
    });

    const getStoredData = (key: string) => {
        const storedData = localStorage.getItem(key);
        if (storedData) {
            return JSON.parse(storedData);
        }
        return null;
    };

    const storeData = (key: string, data: any) => {
        localStorage.setItem(key, JSON.stringify(data));
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                // Check if data is in local storage
                const storedBranches = getStoredData("branches");
                const storedEnvironments = getStoredData("environments");
                const storedSkillLevels = getStoredData("skillLevels");
                const storedFocusGroups = getStoredData("focusGroups");
                const storedCategories = getStoredData("categories");
                const storedDisabilities = getStoredData("disabilities");

                // If all data is present in local storage, use it
                if (
                    storedBranches &&
                    storedEnvironments &&
                    storedSkillLevels &&
                    storedFocusGroups &&
                    storedCategories &&
                    storedDisabilities
                ) {
                    setFilterOptions({
                        branches: storedBranches,
                        environments: storedEnvironments,
                        skillLevels: storedSkillLevels,
                        focusGroups: storedFocusGroups,
                        categories: storedCategories,
                        disabilities: storedDisabilities,
                    });
                } else {
                    // Otherwise, fetch from the server
                    const [
                        branchesResponse,
                        environmentsResponse,
                        skillLevelsResponse,
                        focusGroupsResponse,
                        categoriesResponse,
                        disabilitiesResponse,
                    ] = await Promise.all([
                        fetch(BACKEND_REST_URL + "/api/branches/"),
                        fetch(BACKEND_REST_URL + "/api/environments/"),
                        fetch(BACKEND_REST_URL + "/api/skill_levels/"),
                        fetch(BACKEND_REST_URL + "/api/focus_groups/"),
                        fetch(BACKEND_REST_URL + "/api/categories/"),
                        fetch(BACKEND_REST_URL + "/api/disabilities/"),
                    ]);

                    const branches = await branchesResponse.json();
                    const environments = await environmentsResponse.json();
                    const skillLevels = await skillLevelsResponse.json();
                    const focusGroups = await focusGroupsResponse.json();
                    const categories = await categoriesResponse.json();
                    const disabilities = await disabilitiesResponse.json();

                    // Save fetched data to state
                    setFilterOptions({
                        branches: branches.map((branch: { name: string }) => branch.name),
                        environments: environments.map((environment: { name: string }) => environment.name),
                        skillLevels: skillLevels.map((skillLevel: { name: string }) => skillLevel.name),
                        focusGroups: focusGroups.map((focusGroup: { name: string }) => focusGroup.name),
                        categories: categories.map((category: { name: string }) => category.name),
                        disabilities: disabilities.map((disability: { name: string }) => disability.name),
                    });

                    // Store fetched data in local storage
                    storeData("branches", branches.map((branch: { name: string }) => branch.name));
                    storeData(
                        "environments",
                        environments.map((environment: { name: string }) => environment.name)
                    );
                    storeData(
                        "skillLevels",
                        skillLevels.map((skillLevel: { name: string }) => skillLevel.name)
                    );
                    storeData(
                        "focusGroups",
                        focusGroups.map((focusGroup: { name: string }) => focusGroup.name)
                    );
                    storeData(
                        "categories",
                        categories.map((category: { name: string }) => category.name)
                    );
                    storeData(
                        "disabilities",
                        disabilities.map((disability: { name: string }) => disability.name)
                    );
                }
            } catch (error) {
                console.error("Failed to fetch filter options", error);
            }
        };

        fetchOptions();
    }, []);

    return (
        <Grid container spacing={2} sx={{ padding: stackVertically ? 0 : 2 }}>
            <FilterGrid
                options={filterOptions.branches}
                label={t('activityForm.branch')}
                icon={<DownhillSkiingIcon />}
                stackVertically={stackVertically}
                error={error}
                readOnly={readOnly}
                keywords={activity?.branches?.map((keyword) => keyword.name) ?? []}
                onChange={onChangeBranch}
            />
            <FilterGrid
                options={filterOptions.environments}
                label={t('activityForm.environment')}
                icon={<ForestIcon />}
                stackVertically={stackVertically}
                error={error}
                readOnly={readOnly}
                keywords={activity?.environments?.map((keyword) => keyword.name) ?? []}
                onChange={onChangeEnvironment}
            />
            <FilterGrid
                options={filterOptions.skillLevels}
                label={t('activityForm.skillLevel')}
                icon={<LandscapeIcon />}
                stackVertically={stackVertically}
                error={error}
                readOnly={readOnly}
                keywords={activity?.skill_levels?.map((keyword) => keyword.name) ?? []}
                onChange={onChangeSkillLevel}
            />
            <FilterGrid
                options={filterOptions.focusGroups}
                label={t('activityForm.focusGroup')}
                icon={<PeopleIcon />}
                stackVertically={stackVertically}
                error={error}
                readOnly={readOnly}
                keywords={activity?.focus_groups?.map((keyword) => keyword.name)}
                onChange={onChangeFocusGroup}
            />
            <FilterGrid
                options={filterOptions.categories}
                label={t('activityForm.category')}
                icon={<KeyIcon />}
                stackVertically={stackVertically}
                error={error}
                readOnly={readOnly}
                keywords={activity?.categories?.map((keyword) => keyword.name) ?? []}
                onChange={onChangeCategories}
            />
            <FilterGrid
                options={filterOptions.disabilities}
                label={t('activityForm.disabilities')}
                icon={<AccessibleIcon />}
                stackVertically={stackVertically}
                readOnly={readOnly}
                keywords={activity?.disabilities?.map((keyword) => keyword.name) ?? []}
                onChange={onChangeDisabilities}
            />
        </Grid>
    );
};

export default Filter;