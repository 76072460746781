import React, { useEffect, useRef, useCallback, useState } from "react";
import { Container, Grid, Typography, CircularProgress, Box } from "@mui/material";
import Record from "./Record";
import { RecordData, SessionRecordData } from "../pages/Sessions/types";
import { useSearchContext } from "../contexts/SearchContext";
import { useNavigate } from 'react-router-dom';
import { useRecords } from "../contexts/RecordsContext";
import { useLanguage } from "../contexts/LanguageContext";

const RecordList: React.FC = () => {
  const { searchData } = useSearchContext();
  const {
    setRecord,
    records,
    loading,
    error,
    total,
    clearRecords,
    fetchRecords,
  } = useRecords();
  const { currentLanguage } = useLanguage();
  const [skip, setSkip] = useState(0);
  const LIMIT = 50;

  const observerRef = useRef<IntersectionObserver | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setSkip(0);
    clearRecords();
  }, [searchData, clearRecords]);

  useEffect(() => {
    fetchRecords(skip, LIMIT, searchData, currentLanguage?.id || 2);
  }, [fetchRecords, currentLanguage, skip, LIMIT, searchData]);

  const handleRecordClick = (record: RecordData | SessionRecordData) => {
    const path = searchData.show_activities ? `/activity/${record.id}` : `/session/${record.id}`;
    setRecord(record);
    navigate(path);
  };

  const lastRecordRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading || !searchData.show_activities) return;

      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && records.length < total) {
          setSkip(prev => prev + LIMIT);
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [loading, records.length, total, searchData.show_activities],
  );

  if (error) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Container component="section" maxWidth="lg" sx={{ my: 4 }}>
      {records.length > 0 ? (
        <Grid container spacing={4}>
          {records.map((record, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={record.id}
              ref={index === records.length - 1 ? lastRecordRef : null}
            >
              <Record
                id={record.id}
                name={record.name || record.title || ''}
                description={record.description || ''}
                videoUrl={record.videoUrl || ''}
                onClick={() => handleRecordClick(record)}
              />
            </Grid>
          ))}
        </Grid>
      ) : !loading && (
        <Typography variant="h6" textAlign="center" my={4}>
          No records found
        </Typography>
      )}

      {loading && (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default RecordList;
