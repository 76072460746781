import React from "react";
// import { Box, Divider, Typography } from "@mui/material";
// import Carousel from "../components/carousel/Carousel";
// import CarouselCard from "../components/carousel/CarouselCard";

const MyPage: React.FC = () => {
  // const carouselItems = [
  //   {
  //     title: "Project 1",
  //     description: "Description of project 1.",
  //     imageUrl: "https://picsum.photos/400/200?random=1", // Stock thumbnail image
  //   },
  //   {
  //     title: "Project 2",
  //     description: "Description of project 2.",
  //     imageUrl: "https://picsum.photos/400/200?random=2", // Stock thumbnail image
  //   },
  //   {
  //     title: "Project 3",
  //     description: "Description of project 3.",
  //     imageUrl: "https://picsum.photos/400/200?random=3", // Stock thumbnail image
  //   },
  //   {
  //     title: "Project 4",
  //     description: "Description of project 4.",
  //     imageUrl: "https://picsum.photos/400/200?random=4", // Stock thumbnail image
  //   },
  //   {
  //     title: "Project 5",
  //     description: "Description of project 5.",
  //     imageUrl: "",
  //   },
  // ];

  // const handleCardClick = (title: string) => {
  //   alert(`You clicked on ${title}`);
  // };

  return (
    // <Box
    //   sx={{
    //     padding: 4,
    //     maxWidth: 1200,
    //     margin: "auto",
    //   }}
    // >
    //   <Box
    //     sx={{
    //       marginBottom: 4,
    //     }}
    //   >
    //     <Typography variant="h3" gutterBottom>
    //       Dine økter
    //     </Typography>

    //     <Carousel>
    //       {carouselItems.map((item, index) => (
    //         <CarouselCard
    //           key={index}
    //           title={item.title}
    //           description={item.description}
    //           imageUrl={item.imageUrl}
    //           onClick={() => handleCardClick(item.title)} // Pass the onClick handler
    //         />
    //       ))}
    //     </Carousel>
    //   </Box>
    //   <Divider orientation="horizontal" sx={{}} />
    //   <Box sx={{ marginTop: 4 }}>
    //     <Typography variant="h4" gutterBottom>
    //       Dine øvelser
    //     </Typography>

    //     <Carousel>
    //       {carouselItems.map((item, index) => (
    //         <CarouselCard
    //           key={index}
    //           title={item.title}
    //           description={item.description}
    //           imageUrl={item.imageUrl}
    //           onClick={() => handleCardClick(item.title)} // Pass the onClick handler
    //         />
    //       ))}
    //     </Carousel>
    //   </Box>
    // </Box>
    <>
    </>
  );
};

export default MyPage;
