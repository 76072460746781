import React from "react";
import { Box, Switch, Typography } from "@mui/material";

export interface ToggleSwitchDisplayProps {
  showComponentOne: boolean;
  ComponentOne: React.FC;
  ComponentTwo: React.FC;
}

interface ToggleSwitchProps {
  label1: string;
  label2: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label1,
  label2,
  onChange,
  checked,
}) => {
  const handleToggle = () => {
    onChange(!checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 2,
      }}
    >
      <Typography>{label1}</Typography>
      <Switch checked={checked} onChange={handleToggle} />
      <Typography>{label2}</Typography>
    </Box>
  );
};

export const ToggleDisplay: React.FC<ToggleSwitchDisplayProps> = ({
  showComponentOne,
  ComponentOne,
  ComponentTwo,
}) => {
  return (
    <React.Fragment>
      {showComponentOne ? <ComponentOne /> : <ComponentTwo />}
    </React.Fragment>
  );
};
