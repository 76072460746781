import React, { createContext, useContext, useState, ReactNode } from "react";
import { RecordData } from "../pages/Sessions/types.ts";

export interface ImageDataPost {
  file: File;
  isThumbnail: boolean;
}

interface ValidationErrors {
  title?: string;
  selectedCategory?: string;
  selectedBranches?: string;
  description?: string;
  images?: string;
}

interface FormContextType {
  title: string;
  setTitle: (title: string) => void;
  selectedCategory: string[];
  setSelectedCategory: (categories: string[]) => void;
  selectedBranches: string[];
  handleBranchChange: (branch: string) => void;
  sections: number;
  setSections: (sections: number) => void;
  thumbnailIndex: number;
  setThumbnailIndex: (index: number) => void;
  sectionTitles: string[];
  setSectionTitles: (titles: string[]) => void;
  selectedActivities: RecordData[][];
  setSelectedActivities: (activities: RecordData[][]) => void;
  description: string;
  setDescription: (description: string) => void;
  images: ImageDataPost[];
  addImages: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: (index: number) => void;
  errors: ValidationErrors;
  setErrors: (errors: ValidationErrors) => void;
  validateForm: () => boolean;
  step: number;
  setActiveStep: (index: number) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

interface FormProviderProps {
  children: ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [title, setTitle] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const [sections, setSections] = useState<number>(3);
  const [sectionTitles, setSectionTitles] = useState<string[]>([
    "Oppvarming",
    "Hoveddel",
    "Avslutning",
  ]);
  const [selectedActivities, setSelectedActivities] = useState<RecordData[][]>(
    Array(sections).fill([])
  );
  const [images, setImages] = useState<ImageDataPost[]>([]);
  const [thumbnailIndex, setThumbnailIndex] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [step, setActiveStep] = useState(0);

  const validateForm = (): boolean => {
    let validationErrors: ValidationErrors = {};

    if (step === 0 && selectedBranches.length === 0) {
      validationErrors.selectedBranches = "Minst en gren må velges";
    }
    if (step === 1) {
      if (title.trim() === "") {
        validationErrors.title = "Tittel er påkrevd";
      }
      if (selectedCategory.length === 0) {
        validationErrors.selectedCategory = "Minst en kategori må velges";
      }
      if (description.trim() === "") {
        validationErrors.description = "Fyll inn beskrivelse.";
      }
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const addImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files);
      const newImagePosts: ImageDataPost[] = fileArray.map((file: File, index: number) => ({
        file: file,
        isThumbnail: images.length === 0 && index === 0,
      }));
      setImages([...images, ...newImagePosts]);
    }
  };

  const handleBranchChange = (branch: string) => {
    setSelectedBranches(prev =>
      prev.includes(branch)
        ? prev.filter(b => b !== branch)
        : [...prev, branch]
    );
  };

  const removeImage = (index: number) => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <FormContext.Provider
      value={{
        title,
        setTitle,
        selectedCategory,
        setSelectedCategory,
        selectedBranches,
        handleBranchChange,
        thumbnailIndex,
        setThumbnailIndex,
        sections,
        setSections,
        sectionTitles,
        setSectionTitles,
        selectedActivities,
        setSelectedActivities,
        addImages,
        removeImage,
        images,
        description,
        setDescription,
        errors,
        setErrors,
        validateForm,
        step,
        setActiveStep,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = (): FormContextType => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};