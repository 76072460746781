import React, { useState, useEffect } from "react";
import fallback_img from '../assets/fallback_img.webp';
import { BACKEND_REST_URL } from "../main";
import { useSearchContext } from "../contexts/SearchContext";
import DOMPurify from 'dompurify';

interface RecordProps {
  id: number;
  name: string;
  description: string;
  videoUrl: string;
  onClick: () => void;
}

const Record: React.FC<RecordProps> = ({ id, name, description, videoUrl, onClick }) => {
  const [imageSrc, setImageSrc] = useState<string>(fallback_img);
  const { searchData } = useSearchContext();
  const recordType = searchData.show_activities ? "activities" : "sessions";

  const getYouTubeThumbnail = (url: string) => {
    const videoId = url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([\w-]{11})/)?.[1];
    return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : null;
  };


  const truncatedName = name.length > 30 ? `${name.slice(0, 30)}...` : name;
  const truncatedDescription = description.length > 100
    ? `${description.slice(0, 100)}...`
    : description;

  const sanitizedDescription = DOMPurify.sanitize(truncatedDescription);

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        const thumbnailResponse = await fetch(
          `${BACKEND_REST_URL}/api/${recordType}/${id}/images/thumbnail`
        );

        if (!thumbnailResponse.ok) {
          const thumbnail = getYouTubeThumbnail(videoUrl);
          if (thumbnail) {
            setImageSrc(thumbnail);
          }
          return
        }

        const data = await thumbnailResponse.json();
        if (data.id) {
          setImageSrc(`${BACKEND_REST_URL}/api/image/${data.id}`);
        }
      } catch (error) {
        // console.error('Error fetching thumbnail:', error);
      }
    };

    fetchThumbnail();
  }, [id, recordType]);

  return (
    <div
      className="border p-4 rounded-lg shadow-lg bg-white hover:bg-gray-50 transition cursor-pointer transform hover:scale-102"
      onClick={onClick}
      style={{ height: '350px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <img
        src={imageSrc}
        alt={name}
        className="w-full h-[200px] object-cover rounded-lg mb-4"
        style={{ flexShrink: 0 }}
      />
      <div className="space-y-2 flex-grow overflow-hidden">
        <h3 className="text-lg font-semibold truncate">
          {truncatedName}
        </h3>
        <p
          className="text-sm text-gray-500 line-clamp-3"
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          style={{ maxHeight: '50px', overflow: 'hidden' }}
        />
      </div>
    </div>
  );
};

export default Record;
