import React from "react";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface searchbarProps {
  label: string;
  onChange: (searchText: string) => void;
}

const Searchbar: React.FC<searchbarProps> = (props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  return (
    <Box
      component="form"
      role="search"
      aria-label={props.label}
      sx={{ display: "flex", justifyContent: "center", p: 2 }}
    >
      <TextField
        id="search-workouts"
        label={props.label}
        variant="outlined"
        fullWidth
        aria-label={props.label}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="search" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
export default Searchbar;
