import { Box, Chip, Typography } from "@mui/material";
import { Category } from "../../../pages/Sessions/types";

type CategoryListProps = {
    categories: Category[];
};

const CategoryList: React.FC<CategoryListProps> = ({ categories }) => (
    <div className="bg-white rounded-lg shadow-md border-2  lg:p-4 flex flex-col p-5  justify-center items-center">
        <Typography variant="h6" className="mb-2 lg:mb-4 text-center pb-3">
            Kategori
        </Typography>
        <Box className="flex flex-wrap items-center justify-center ">
            {categories.map((cat) => (
                <Chip
                    key={cat.id}
                    label={cat.name}
                    color="primary"
                    className="mr-2 mb-2"
                />
            ))}
        </Box>
    </div>
);

export default CategoryList;