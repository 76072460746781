import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useFormContext } from "../../contexts/FormContext";
import { useTranslation } from 'react-i18next';
import { useOptions } from "../../contexts/OptionsContext";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import HikingIcon from "@mui/icons-material/Hiking";
import SnowboardingIcon from "@mui/icons-material/Snowboarding";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";

const CategoryCard: React.FC<{
  icon: React.ReactNode;
  label: string;
  selected: boolean;
  onClick: () => void;
}> = ({ icon, label, selected, onClick }) => {
  return (
    <Box
      className={`flex flex-col items-center cursor-pointer p-4 rounded-md transition-colors duration-300 ${selected ? "bg-blue-200" : "hover:bg-gray-200"
        }`}
      onClick={onClick}
    >
      <Box className="text-4xl mb-2">{icon}</Box>
      <Typography variant="body1" className="mt-1">
        {label}
      </Typography>
    </Box>
  );
};

// TODO: Load from backend
const getIconForBranch = (branchName: string): React.ReactNode => {
  console.log(branchName)
  switch (branchName.toLowerCase()) {
    case 'alpint':
    case 'telemark':
    case 'randonee':
    case 'kombinert':
      return <DownhillSkiingIcon />;
    case 'langrenn':
      return <HikingIcon />;
    case 'hopp':
      return <AirlineStopsIcon />;
    case 'freestyle':
      return <SnowboardingIcon />;
    default:
      return <AirlineStopsIcon />;
  }
};

const BranchSelection: React.FC = () => {
  const { selectedBranches, handleBranchChange, errors } = useFormContext();
  const { branchOptions } = useOptions();
  const { t } = useTranslation();
  console.log(branchOptions)

  if (branchOptions.length === 0) {
    return (
      <Box className="p-4 flex flex-col items-center">
        <Typography variant="h5" className="mb-4">
          {t('createSession.steps.0')}
        </Typography>
        <Typography>{t('createSession.error.NoBranches')}</Typography>
      </Box>
    );
  }

  return (
    <Box className="p-4 flex flex-col items-center">
      <Typography variant="h5" className="mb-4">
        {t('createSession.steps.0')}
      </Typography>
      {errors.selectedBranches && <span style={{ color: 'red' }}>{t('createSession.error.selectedBranches')}</span>}
      <Grid
        container
        spacing={2}
        className='max-w-screen-md'
        justifyContent="center"
      >
        {branchOptions.map((option, index) => (
          <Grid item xs={6} sm={4} md={4} key={index}>
            <CategoryCard
              icon={getIconForBranch(option.name)}
              label={option.name}
              selected={selectedBranches.includes(option.name)}
              onClick={() => handleBranchChange(option.name)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BranchSelection;