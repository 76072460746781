import { useTranslation } from "react-i18next";
import { KeyWord, TagGridProps, TagSectionProps } from "../../pages/Sessions/types";
import { useCreateActivity } from "../../contexts/CreateActivityContext";
import { useState } from 'react';
import { CheckCircle, Close } from "@mui/icons-material";



const Chip = ({ item, color, onChange, className }: {
  item: KeyWord,
  color: string,
  onChange?: (value: string) => void,
  className?: string,
}) => {
  return (
    <div className={`rounded-full px-3 py-1 ${color} text-gray-800 text-sm font-medium flex items-center gap-2 ${className}`} onClick={() => onChange && onChange(item.name)}  >
      {item.name}
    </div>
  );
}
const EditableChip = ({ item, color, onChange, checked, isEditing, onConfirm, onCancel }: {
  item: KeyWord,
  color: string,
  onChange?: (value: string) => void,
  checked: boolean,
  isEditing?: boolean,
  onConfirm?: (value: string) => void,
  onCancel?: () => void,
}) => {
  const [value, setValue] = useState(item.name);
  console.log(checked, "checked")

  if (isEditing) {
    return (
      <div className={`rounded-full px-4 py-1 ${color} text-gray-800 text-sm font-medium flex items-center gap-2`}>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="bg-transparent outline-none"
          autoFocus
        />
        <>
          <button className="hover:text-green-500" onClick={() => onConfirm && onConfirm(value)}><CheckCircle /></button>
          <button className="hover:text-red-500" onClick={onCancel}><Close /></button>
        </>
      </div>
    );
  }

  return <Chip item={item} color={color} onChange={onChange} className={checked ? 'cursor-pointer' : 'opacity-50 cursor-pointer'} />;
}

const TagSection: React.FC<TagSectionProps> = ({
  title,
  icon,
  items,
  color,
}) => {
  const { t } = useTranslation();
  return (
    <div className="border p-4 rounded-lg shadow-lg bg-white hover:bg-gray-50 transition">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold flex items-center">
          <span className="text-2xl mr-2">{icon}</span>
          {title}
        </h3>
      </div>
      <div className="flex flex-wrap gap-2 mt-3">
        {items.length ? (
          items.map((item: KeyWord) => (
            <Chip
              key={item.id}
              item={item}
              color={color}
            />
          ))
        ) : (
          <p className="text-sm text-gray-500">{t('activityForm.noItemsAvailable')}</p>
        )}
      </div>
    </div>
  );
};

const EditableTagSection: React.FC<TagSectionProps> = ({
  title,
  icon,
  items,
  color,
  onChange,
  isChecked,
  sectionKey,
}) => {
  const { t } = useTranslation();
  const [newItem, setNewItem] = useState<KeyWord | null>(null);

  const { errors } = useCreateActivity();

  const onAdd = () => {
    setNewItem({
      id: items.length + 1,
      name: '',
    });
  };

  const handleConfirm = (value: string) => {
    if (newItem && onChange) {
      const Item = {
        id: items.length + 1,
        name: value,
      }
      setNewItem(Item);
      onChange(Item.name);
      items.push(Item);
      setNewItem(null);
    }
  };

  const handleCancel = () => {
    setNewItem(null);
  };

  return (
    <div className={`border p-4 rounded-lg shadow-lg bg-white hover:bg-gray-50 transition ${errors[sectionKey] ? 'border-red-500' : ''}`}>
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold flex items-center">
          <span className="text-2xl mr-2">{icon}</span>
          {title}
        </h3>
        <button
          onClick={onAdd}
          className="text-blue-600 hover:text-blue-800 disabled:text-gray-400"
        >
          +
        </button>
      </div>
      <div className={`flex flex-wrap gap-2 mt-3 `}>
        {items.length ? (
          items.map((item: KeyWord) => (
            <EditableChip
              key={item.id}
              item={item}
              color={color}
              onChange={onChange ? (value) => onChange(value) : undefined}
              checked={isChecked ? isChecked(item.name) : false}
            />
          ))
        ) : (
          <p className="text-sm text-gray-500">{t('activityForm.noItemsAvailable')}</p>
        )}
        {newItem && (
          <EditableChip
            item={newItem}
            color={color}
            onChange={() => { }}
            checked={false}
            isEditing={true}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
    </div>
  );
};

export const TagGrid: React.FC<TagGridProps> = ({
  activity,
  isEditable,
  onChange,
  isChecked,

}) => {
  const { t } = useTranslation();

  const sections = [
    { key: "skill_levels", title: t('activityForm.skillLevel'), items: activity.skill_levels, icon: '🔰', color: 'bg-green-200' },
    { key: "focus_groups", title: t('activityForm.focusGroup'), items: activity.focus_groups, icon: '👥', color: 'bg-blue-200' },
    { key: "categories", title: t('activityForm.category'), items: activity.categories, icon: '📋', color: 'bg-purple-200' },
    { key: "branches", title: t('activityForm.branch'), items: activity.branches, icon: '🌐', color: 'bg-red-200' },
    { key: "environments", title: t('activityForm.environment'), items: activity.environments, icon: '🏞️', color: 'bg-yellow-200' },
    { key: "disabilities", title: t('activityForm.disabilities'), items: activity.disabilities, icon: '🦮', color: 'bg-indigo-200' },
    { key: "equipment", title: t('activityForm.equipment'), items: activity.equipment, icon: '⚙️', color: 'bg-pink-200' },
  ];
  if (isEditable) {
    return (
      <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {sections.map((section) => (
          <EditableTagSection
            title={section.title}
            key={section.key}
            sectionKey={section.key}
            icon={section.icon}
            items={section.items}
            color={section.color}
            isEditable={isEditable}
            onChange={onChange ? (value) => onChange(section.key, value) : undefined}
            isChecked={isChecked ? (value) => isChecked(section.key, value) : undefined}
          />
        ))}
      </div>
    )
  }

  return (
    <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {sections.map((section) => (
        <TagSection
          title={section.title}
          key={section.key}
          sectionKey={section.key}
          icon={section.icon}
          items={section.items}
          color={section.color}
          isEditable={isEditable}
          onChange={onChange ? (id) => onChange(section.key, id) : undefined}
        />
      ))}
    </div>
  );
};