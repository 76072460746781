import React, { useEffect, useState } from "react";
import { useFormContext } from "../../contexts/FormContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import StarIcon from "@mui/icons-material/Star";


interface ButtonGroupProps {
    onRemove: () => void;
    onSetThumbnail: () => void;
    onAdd: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isThumbnail: boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ onRemove, onSetThumbnail, onAdd, isThumbnail }) => {
    return (
        <div className="flex space-x-0">
            {/* Remove Image Button */}
            <button
                type="button"
                onClick={onRemove}
                className="bg-red-500 text-white p-3 rounded-l-full focus:outline-none hover:bg-red-400 transition"
            >
                <RemoveIcon />
            </button>

            {/* Set as Thumbnail Button */}
            <button
                type="button"
                onClick={onSetThumbnail}
                className={`bg-yellow-500 text-white p-3 focus:outline-none hover:bg-yellow-400 transition ${isThumbnail ? "opacity-100" : "opacity-75"
                    }`}
            >
                <StarIcon />
            </button>

            {/* Add Image Button */}
            <label className="bg-green-500 text-white p-3 rounded-r-full focus:outline-none hover:bg-green-400 transition cursor-pointer">
                <AddIcon />
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={onAdd}
                    className="hidden"
                />
            </label>
        </div>
    );
};


const SessionImageCarousel: React.FC = () => {
    const { images, addImages, removeImage, thumbnailIndex, setThumbnailIndex } = useFormContext(); // Use context methods
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageURLs, setImageURLs] = useState<string[]>([]); // Manage image URLs
    // const [thumbnailIndex, setThumbnailIndex] = useState<number>(0); // Track selected thumbnail index

    useEffect(() => {
        if (images.length > 0) {
            const newImageURLs = images.map((image) => URL.createObjectURL(image.file));
            setImageURLs(newImageURLs);
        }
    }, [images]);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const setAsThumbnail = () => {
        setThumbnailIndex(currentImageIndex); // Set the current image as the thumbnail
    };

    return (
        <div className="relative w-full h-80 bg-gray-200 rounded-lg overflow-hidden">
            {imageURLs.length > 0 ? (
                <>
                    {/* Image */}
                    <img
                        src={imageURLs[currentImageIndex]}
                        alt="Uploaded"
                        className="object-cover w-full h-full transition-opacity duration-700 ease-in-out"
                        key={currentImageIndex}
                    />

                    {/* Previous Button */}
                    <button
                        type="button"
                        onClick={prevImage}
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-90 p-2 rounded-full focus:outline-none transition"
                    >
                        {"<"}
                    </button>

                    {/* Next Button */}
                    <button
                        type="button"
                        onClick={nextImage}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-90 p-2 rounded-full focus:outline-none transition"
                    >
                        {">"}
                    </button>

                    {/* Thumbnail Indicator */}
                    {thumbnailIndex === currentImageIndex && (
                        <div className="absolute top-4 right-4 bg-yellow-500 text-white p-1 rounded-full">
                            Miniatyr
                        </div>
                    )}

                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
                        <ButtonGroup
                            onRemove={() => removeImage(currentImageIndex)}
                            onSetThumbnail={setAsThumbnail}
                            onAdd={(e) => addImages(e)}
                            isThumbnail={thumbnailIndex === currentImageIndex}
                        />
                    </div>
                </>
            ) : (
                <label className="absolute inset-0 flex flex-col items-center justify-center text-center bg-gray-400 cursor-pointer">
                    <p className="text-gray-500">Klikk for å laste opp bilde</p>
                    {/* <p className="text-gray-400 text-sm"></p> */}
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={addImages}
                        className="hidden"
                    />
                </label>
            )}
        </div>
    );
};

export default SessionImageCarousel;