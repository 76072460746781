import { Box, Button, Chip, CircularProgress, Divider, Typography } from "@mui/material";
import { QuillViewer } from "../../components/createActivity/richEditor";
import { BACKEND_REST_URL } from "../../main";
import SectionStepGrid from "../../components/Sessions/sectionStepGrid";

import { ImageDataPost, useFormContext } from "../../contexts/FormContext";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SessionImageCarousel from "../../components/Sessions/SessionImageCarousel";

const ConclusionStep = () => {
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const { selectedBranches, images, title, selectedCategory, description, sections, sectionTitles, selectedActivities, thumbnailIndex } = useFormContext();
    const [loading, setLoading] = useState(false);
    const uploadImages = async (dataId: number, images: ImageDataPost[], token: string) => {
        const uploadPromises = images.map(({ file }, index) => {
            const formData = new FormData();
            formData.append('file', file);
            const imageUrl = `${BACKEND_REST_URL}/api/sessions/${dataId}/images?is_thumbnail=${index == thumbnailIndex}`;
            return fetch(imageUrl, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        });

        return Promise.all(uploadPromises);
    };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const formData = {
            branches: [...selectedBranches],
            title,
            categories: [...selectedCategory],
            description: description,
            sections,
            section_titles: sectionTitles,
            selected_activities: selectedActivities.map((section) =>
                section.map((activity) => ({ id: activity.id })),
            ),
        };

        setLoading(true);
        try {
            const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
            console.log('Token:', token)

            if (!token) {
                navigate('/signin')
            }
            const url = BACKEND_REST_URL + '/api/sessions'
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (images.length > 0)
                await uploadImages(data.id, images, token!)

        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setLoading(false);
            showSnackbar('Ny aktivitet lagret!', 'success');
        }


    };
    return (


        <Box
            component="form"
            onSubmit={handleSubmit}
            className="container mx-auto p-4 lg:p-6"
        >
            <div className="container mx-auto p-4 lg:p-6">
                <div className="text-center mb-6 lg:mb-8">
                    <Typography
                        variant="h5"
                        className="font-semibold text-lg lg:text-2xl"
                    >
                        {title}
                    </Typography>
                </div>



                <Divider className="py-4 my-6 lg:my-8">
                    <b>Informasjon</b>
                </Divider>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
                    <div className="bg-white rounded-lg shadow-md p-4 lg:p-6">
                        <Typography variant="h6" className="mb-4">
                            Kategori
                        </Typography>
                        <Box className="mb-6">
                            {selectedCategory.map((cat) => (
                                <Chip
                                    key={cat}
                                    label={cat}
                                    color={selectedCategory.includes(cat) ? "primary" : "default"}
                                    className="mr-2 mb-2"
                                />
                            ))}
                        </Box>

                        <div className="mt-6">
                            <Typography variant="h6" className="mb-4">
                                Antall Seksjoner
                            </Typography>
                            <div className="flex items-center justify-center space-x-4">
                                <div className="text-xl lg:text-2xl font-bold">{sections}</div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-lg shadow-md p-4 lg:p-6">
                        <div className="flex justify-center items-center relative">
                            {images.length > 0 ? (
                                <SessionImageCarousel />
                                // <img
                                //     src={URL.createObjectURL(images[0].file)}
                                //     alt="Selected"
                                //     className="object-cover h-40 w-full lg:h-48 rounded-lg"
                                // />
                            ) : (
                                <div className="text-center">
                                    <p className="text-gray-500">Ingen bilde tilgjengelig</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <Divider className="py-4 mt-6 lg:mt-8">
                    <b>Øvelser</b>
                </Divider>

                <QuillViewer content={description} />
            </div>

            {sectionTitles.map((sectionTitle, index) => (


                <SectionStepGrid sectionTitle={sectionTitle} selectedActivities={selectedActivities[index]} />
            ))}

            <Box className="flex justify-between mt-4">
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Fullfør"}
                </Button>
            </Box>
        </Box>
    );
}

export default ConclusionStep;