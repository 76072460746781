import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Container, Box, useMediaQuery, useTheme } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButtonWithText } from "./components/utils";
import { useAuth } from "./contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import FloatingActionMenu from "./components/FloatingActionMenu";
import LanguageIcon from '@mui/icons-material/Language';
import LanguageModal from "./components/translationBar/LanguageModal";

import Home from "./pages/Home";
import { SignInPage } from "./pages/signin";
import CreateSession from "./pages/Sessions/CreateSession";
import MyPage from "./pages/MyPage";
import ActivityView from "./pages/ActivityView";
import SessionView from "./pages/SessionView";
import { ActivityCreate } from "./components/Activity/ActivityCreate";

const App: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoggedIn, userData } = useAuth();
  const { t, i18n } = useTranslation();
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  const floatingActions = [
    // {
    //   label: t('common.createSession'),
    //   name: t('common.createSessionName'),
    //   to: "/createSession"
    // },
    {
      label: t('common.createActivity'),
      name: t('common.createActivityName'),
      to: "/createActivity"
    }
  ];

  const handleLanguageChange = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };

  const getLanguageName = (languageCode: string) => {
    // Use the t function to translate language names
    return t(`language:${languageCode}`, { lng: languageCode });
  };

  const renderNavigation = () => (
    <Toolbar disableGutters className="flex justify-between items-center">
      <Typography variant="h6" component={Link} to="/" className="flex-grow no-underline text-inherit">
        {t('common.activityPlanner')}
      </Typography>
      <div className="flex items-center">
        <IconButtonWithText
          icon={<HomeIcon />}
          text={isMobile ? '' : t('common.home')}
          to="/"
        />
        <IconButtonWithText
          icon={<AccountCircleIcon />}
          text={isMobile ? '' : (isLoggedIn ? userData.user_name : t('common.signIn'))}
          to={isLoggedIn ? "/my-page" : "/signin"}
        />
        <IconButtonWithText
          icon={<LanguageIcon />}
          text={isMobile ? '' : getLanguageName(i18n.language)}
          onClick={() => setIsLanguageModalOpen(true)}
        />
      </div>
    </Toolbar>
  );

  return (
    <Box className="flex flex-col min-h-screen">
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          color: "#343a40",
          top: isMobile ? 'auto' : 0,
          bottom: isMobile ? 0 : 'auto',
        }}
      >
        <Container>{renderNavigation()}</Container>
      </AppBar>

      <LanguageModal
        isOpen={isLanguageModalOpen}
        onClose={() => setIsLanguageModalOpen(false)}
        onLanguageChange={handleLanguageChange}
      />


      <Box
        component="main"
        className="flex-grow"
        sx={{
          pt: isMobile ? 0 : 8,
          pb: isMobile ? 8 : 0,
          mt: 2
        }}
      >
        <Routes>
          <Route path="/createActivity" element={<ActivityCreate />} />
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/my-page" element={<MyPage />} />
          <Route path="/createSession" element={<CreateSession />} />
          <Route path="/activity/:id" element={<ActivityView />} />
          <Route path="/session/:id" element={<SessionView />} />
        </Routes>
      </Box>

      <div className="mx-auto">
        {isLoggedIn && <FloatingActionMenu actions={floatingActions} />}

      </div>
    </Box>
  );
};

export default App;