import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SessionForm from '../components/Sessions/SessionForm';
import { useRecords } from '../contexts/RecordsContext';
import { SessionRecordData } from './Sessions/types';
import { useLanguage } from '../contexts/LanguageContext';

const SessionView: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { record, loading, error, fetchRecord } = useRecords();
    const { currentLanguage } = useLanguage();

    if (loading) {
        return (
            <div className="flex justify-center my-4 container mx-auto">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mx-auto">
                <div className="text-xl text-red-600 text-center">
                    {error}
                </div>
            </div>
        );
    }

    if (!record && id) {
        fetchRecord(parseInt(id), "sessions", currentLanguage?.id || 0);
    }

    return (
        <div className="container mx-auto max-w-7xl relative">
            <button
                onClick={() => navigate(-1)}
                className="absolute right-4 top-4 p-2 rounded-full hover:bg-gray-100"
            >
                <CloseIcon />
            </button>
            {record && <SessionForm record={record as SessionRecordData} />}
        </div>
    );
};

export default SessionView;


