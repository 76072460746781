import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";

// Define the context and its type
interface ScreenModeContextType {
  isMobile: boolean;
}

const ScreenModeContext = createContext<ScreenModeContextType | undefined>(
  undefined,
);

// Define the props interface for the provider
interface ScreenModeProviderProps {
  children: ReactNode;
}

// Create a provider component
export const ScreenModeProvider: React.FC<ScreenModeProviderProps> = ({
  children,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ScreenModeContext.Provider value={{ isMobile }}>
      {children}
    </ScreenModeContext.Provider>
  );
};

// Create a custom hook for easier context consumption
export const useScreenMode = (): ScreenModeContextType => {
  const context = useContext(ScreenModeContext);
  if (!context) {
    throw new Error("useScreenMode must be used within a ScreenModeProvider");
  }
  return context;
};
