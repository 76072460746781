import React from 'react';
import { Save } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCreateActivity } from '../contexts/CreateActivityContext';
import { useSnackbar } from '../contexts/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../contexts/LanguageContext';

const SaveFabComponent: React.FC = () => {
    const navigate = useNavigate();
    const { validateActivity, handlePost, activityPost, images } = useCreateActivity();
    const { activityLanguage } = useLanguage();
    const { showSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleSave = async () => {
        try {
            // Call your validation function here
            const isValid = validateActivity();
            if (!isValid) {
                showSnackbar(t('createActivity.common.error'), 'error');
                return;
            }

            console.log(activityPost, "activityPost")
            if (isValid) {
                await handlePost(activityPost, images, activityLanguage!.id);
                navigate('/');
            }
        } catch (error) {
            console.error('Error validating activity:', error);
            // Handle validation error (maybe show a toast notification)
        }
    };

    return (
        <button
            onClick={handleSave}
            className="flex items-center justify-center w-16 h-16 bg-blue-500 hover:bg-blue-600 text-white rounded-full shadow-lg transition-transform"
        >
            <Save fontSize="large" />
        </button>
    );
};

export default SaveFabComponent; 


