import * as React from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

interface SignInFormProps {
  isSignUp: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  passwordMismatch: boolean;
  setPasswordMismatch: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSignUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignInForm: React.FC<SignInFormProps> = ({
  isSignUp,
  onSubmit,
  passwordMismatch,
  setPasswordMismatch,
  setIsSignUp,
}) => {
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    let username = "";
    let passwordVerify = "";

    if (isSignUp) {
      username = formData.get("username") as string;
      passwordVerify = formData.get("passwordVerify") as string;

      if (!username) {
        setErrors((prev) => ({ ...prev, username: "Brukernavn er påkrevd." }));
        return;
      }
      if (password !== passwordVerify) {
        setErrors((prev) => ({
          ...prev,
          passwordVerify: "Passordene er ikke like.",
        }));
        setPasswordMismatch(true);
        return;
      }
    }

    if (!email) {
      setErrors((prev) => ({ ...prev, email: "E-post er påkrevd." }));
      return;
    }

    if (!password) {
      setErrors((prev) => ({ ...prev, password: "Passord er påkrevd." }));
      return;
    }

    setErrors({});
    setPasswordMismatch(false);
    await onSubmit(event);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      {isSignUp && (
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Brukernavn"
          name="username"
          autoComplete="username"
          autoFocus
          error={!!errors.username}
          helperText={errors.username}
        />
      )}
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="E-post"
        name="email"
        autoComplete="email"
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Passord"
        type="password"
        id="password"
        autoComplete="current-password"
        error={!!errors.password}
        helperText={errors.password}
      />
      {isSignUp && (
        <TextField
          margin="normal"
          required
          fullWidth
          name="passwordVerify"
          label="Verifiser passord"
          type="password"
          id="passwordVerify"
          error={!!errors.passwordVerify}
          helperText={errors.passwordVerify}
        />
      )}
      {passwordMismatch && (
        <Typography variant="body2" color="error">
          Passordene er ikke like.
        </Typography>
      )}
      {!isSignUp && (
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Husk meg"
        />
      )}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {isSignUp ? "Ny bruker" : "Logg inn"}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2">
            Glemt passord?
          </Link>
        </Grid>
        <Grid item>
          <Link href="#" variant="body2" onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp
              ? "Har du allerede en konto? Logg inn"
              : "Har du ikke en konto? Registrer bruker"}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignInForm;
