import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "nb",
    detection: {
      order: ['navigator']
    },
    interpolation: {
      escapeValue: false
    },
    backend :{
      loadPath : '/locales/{{lng}}/translation.json'
    }
  });

  export default i18n;