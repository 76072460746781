import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface HeaderWithBackProps {
    children?: React.ReactNode;
    onBackClick?: () => void;
}

export const HeaderWithBack: React.FC<HeaderWithBackProps> = ({
    children,
    onBackClick
}) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (onBackClick) {
            onBackClick();
        } else {
            navigate('/');
        }
    };

    return (
        <div className="flex items-center py-4 px-6 bg-gray-100 rounded-md shadow-md mb-6">
            <button
                onClick={handleBackClick}
                className="p-1 mr-2 rounded-full hover:bg-gray-200 transition"
                aria-label="Go back to homepage"
            >
                <ArrowBackIcon fontSize="medium" />
            </button>
            {children}
        </div>
    );
}; 