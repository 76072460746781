import { Divider, Typography } from "@mui/material";

type SectionCountProps = {
    sections: number;
};

const SectionCount: React.FC<SectionCountProps> = ({ sections }) => (
    <div className="bg-white rounded-lg shadow-md p-2 lg:p-4 flex border-2 flex-col items-center justify-center">
        <Typography variant="h6" className="mb-2 lg:mb-4 p-2">
            Antall Seksjoner
        </Typography>
        <Divider orientation='horizontal' />
        <div className="flex items-center justify-center ">
            <div className="text-xl lg:text-2xl font-bold">
                {sections}
            </div>
        </div>
    </div>
);

export default SectionCount;