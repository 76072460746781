// GlobalProvider.tsx
import React, { ReactNode } from "react";
import { SearchProvider } from "./SearchContext";
import { FormProvider } from "./FormContext";
import { AuthProvider } from "./AuthContext";
import { OptionsProvider } from "./OptionsContext";
import { RecordsProvider } from "./RecordsContext";
import { CreateActivityProvider } from "./CreateActivityContext";
import { SnackbarProvider } from "./SnackbarProvider";
import { LanguageProvider } from "./LanguageContext";

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  return (
    <AuthProvider>
      <LanguageProvider>
        <RecordsProvider>
          <CreateActivityProvider>

            <FormProvider>
              <OptionsProvider>
                <SearchProvider>
                  <SnackbarProvider>{children}</SnackbarProvider>
                </SearchProvider>
              </OptionsProvider>
            </FormProvider>
          </CreateActivityProvider>
        </RecordsProvider>
      </LanguageProvider>
    </AuthProvider>
  );
};

export default GlobalProvider;
